import React from "react";
import styled from "styled-components";
import { SeoData, ExitRamp } from "components";
import { H2, H3, P, Container, SmallContainer, Grid, Button } from "styles";
import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";

const ResourcesPage = () => {
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.25, duration: 0.75 }}
    >
      <SeoData
        pageSpecificTitle="For Healthcare Professionals"
        pageSpecificDescription="The information on this part of the AeroChamber® website by Teva is suitable for, and solely intended for, IE Healthcare professionals. Contact Teva: T: +44 207 540 7117 or E: Medinfo:@tevauk.com"
      />
      <SmallContainer id="patients">
        <H2 centered style={{ marginBottom: "5rem" }}>
          Clinical Evidence and Information
        </H2>
        <P centered>
          Trudell Medical International has a strong history of leadership in
          creating innovative medical devices that enhance the quality of life
          for people of all ages - all backed by science and clinical evidence.
        </P>
        <P centered>
          Use the links below to find the latest clinical information regarding
          AEROCHAMBER PLUS<sup>®</sup> FLOW-VU<sup>®</sup>
        </P>
      </SmallContainer>
      <Container>
        <Grid>
          {/* <GridItem>
            <StaticImage
              src="../images/clinical-info-1.jpg"
              alt="Clinical Reference Sheet"
              placeholder="blurred"
              layout="fixed"
              loading="lazy"
              objectFit="contain"
              className="product-image"
            />
            <Caption>
              <H3>Clinical reference sheet</H3>
              <P>
                A brief overview of the latest clinical information regarding
                AEROCHAMBER* Brand of VHC.
              </P>
              <a
                href="/pdfs/aerochamber-clinical-reference-sheet.pdf"
                target="_blank" rel="noreferrer"
              >
                Download PDF
              </a>
            </Caption>
          </GridItem>
          <GridItem>
            <StaticImage
              src="../images/clinical-info-2.jpg"
              alt="Chamber Study Summary"
              placeholder="blurred"
              layout="fixed"
              loading="lazy"
              objectFit="contain"
              className="product-image"
            />
            <Caption>
              <H3>AEROCHAMBER PLUS* FLOW-VU<sup>®</sup> Chamber study summary</H3>
              <a href="/pdfs/aerochamber-study-summary.pdf" target="_blank" rel="noreferrer">
                Download PDF
              </a>
            </Caption>
          </GridItem> */}
          <GridItem>
            <StaticImage
              src="../images/clinical-info-3.png"
              alt="In Vitro Study"
              placeholder="blurred"
              layout="fixed"
              loading="lazy"
              objectFit="contain"
              className="product-image"
            />
            <Caption>
              <H3>In vitro study</H3>
              <P>
                Lower-priced chambers could compromise young asthma patients'
                treatment.
                <br />
                Presented at the British Thoracic Society December 2018.
              </P>
              <a href="/pdfs/MD_194A_0818.pdf" target="_blank" rel="noreferrer">
                Download PDF
              </a>
              <P style={{ marginTop: "-1rem" }}>
                <small>AER-IE-00010</small>
              </P>
            </Caption>
          </GridItem>
          <GridItem>
            <StaticImage
              src="../images/clinical-info-4.png"
              alt="Clinical Reference Sheet"
              placeholder="blurred"
              layout="fixed"
              loading="lazy"
              objectFit="contain"
              className="product-image"
            />
            <Caption>
              <H3>Real-world retrospective study</H3>
              <P>Device choice impacts asthma control</P>
              <ExitRamp
                style={{ marginTop: "auto !important" }}
                trigger={
                  <button type="button" aria-label="Trudell Study">
                    Click here for more information
                  </button>
                }
                modalLink={`https://www.trudellmed.com/uk/en-GB/news/new-published-evidence-reinforces-importance-device-choice-asthma-control`}
              />
            </Caption>
          </GridItem>
        </Grid>
      </Container>
    </motion.main>
  );
};

const Caption = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  ${P} {
    line-height: 1.5em !important;
    font-size: 1rem !important;
    margin-bottom: 1rem;
    @media (min-width: 1150px) {
      line-height: 1.5em !important;
      font-size: 1rem !important;
    }
  }
  a,
  button {
    color: var(--primary-color);
    text-decoration: underline;
    font-size: 0.875rem;
    font-family: "Helvetica Neue";
    font-weight: 700;
    margin-top: auto;
    margin-bottom: 1rem;
  }
  div {
    margin-top: auto;
  }
  @media (min-width: 768px) {
    padding: 1.5rem;
  }
`;

const GridItem = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  background: rgba(0, 127, 200, 0.1);
  padding: 1rem;
  p {
    font-size: 1rem;
  }
  .gatsby-image-wrapper {
    width: auto !important;
    height: 200px !important;
    img {
      object-position: center;
    }
  }
  &:nth-child(1) {
    grid-area: 1/ 1 / span 1 / span 7;
  }
  &:nth-child(2) {
    grid-area: 2/ 1 / span 1 / span 7;
  }
  &:nth-child(3) {
    grid-area: 3/ 1 / span 1 / span 7;
  }
  &:nth-child(4) {
    grid-area: 4/ 1 / span 1 / span 7;
  }
  @media (min-width: 768px) {
    .gatsby-image-wrapper {
      width: auto !important;
      height: 200px !important;
      img {
        object-position: center left;
      }
    }
  }
  @media (min-width: 768px) {
    &:nth-child(1) {
      grid-area: 1/ 1 / span 1 / span 6;
    }
    &:nth-child(2) {
      grid-area: 1/ 7 / span 1 / span 6;
    }
    &:nth-child(3) {
      grid-area: 2/ 1 / span 1 / span 6;
    }
    &:nth-child(4) {
      grid-area: 2/ 7 / span 1 / span 6;
    }
  }
  @media (min-width: 1150px) {
    grid-template-columns: 1fr 2fr;
    &:nth-child(1) {
      grid-area: 1/ 2 / span 1 / span 5;
    }
    &:nth-child(2) {
      grid-area: 1/ 7 / span 1 / span 5;
    }
    &:nth-child(3) {
      grid-area: 2/ 2 / span 1 / span 5;
    }
    &:nth-child(4) {
      grid-area: 2/ 7 / span 1 / span 5;
    }
  }
`;

export default ResourcesPage;
